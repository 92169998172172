import axios from 'axios';
const BASE_URL = process.env.REACT_APP_ENDPOINT_LOGIN;

// Você pode definir configurações globais para o Axios, como URL base, headers, etc.
const apiClient = axios.create({
    baseURL: "https://apps-middleware.gwsv2b.easypanel.host",
    headers: {
        'Accept': "*/*"
    }
});
apiClient.interceptors.request.use(async (config) => {
    config.headers['Token'] = localStorage.getItem('token')
    return config;
});

const apiClientDirect = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT_LOGIN,
    headers: {
        'Accept': "application/json",  // Aceitar respostas JSON
        'Content-Type': "application/json"  // Definir o tipo de conteúdo como JSON (para requisições que enviam dados)
    }
});



apiClientDirect.interceptors.request.use(async (config) => {
    config.headers['token'] = localStorage.getItem('token')
    return config;
});

//Inicializa o Client
async function clientConnect() {
    try {
        return await apiClient.post('/users/connect');
    } catch (error) {
        return error
    }
}

async function clientDisconnect() {
    try {
        return await apiClient.post('/users/disconnect');
    } catch (error) {
        return error
    }
}

//Gera o QrCode
async function generateQrCode() {
    try {
        const response = await apiClient.get('/users/qr')
        return response.data;

    } catch (error) {
        throw error; // ou manipule o erro como preferir
    }
}

//Pega informações sobre o contato.
async function contactInfo() {
    const maxRetries = 5;
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    
    let attempt = 0;
    console.log('tentativas de puxar');
    while (attempt < maxRetries) {
        try {
            const response = await apiClientDirect.get('wago/contacts/getall');
            return response.data;  // Retorna se a requisição for bem-sucedida
        } catch (error) {
            attempt++;
            if (attempt === maxRetries) {
                throw error;  // Lança o erro após 5 tentativas falhadas
            }
            console.log(`Tentativa ${attempt} falhou. Tentando novamente...`);
            await delay(1000);  // Aguarda 1 segundo antes de tentar novamente
        }
    }
}

async function clientStatus() {
    try {
        const response = await apiClient.get('/users/status')
        return response.data;

    } catch (error) {
        throw error; // ou manipule o erro como preferir
    }
}

// async function sendMessage(phone, message) {
//     try{
//         const data = {phoneNumber: phone, message: message}
//         return await apiClient.post('/chats/text', data)
//     } catch (error){
//         return error
//     }
// }
async function sendMessages(phones, message) {
    try{
        const data = {phones: phones, message: message}
        return await apiClient.post('/chats/texts', data)
    } catch (error){
        return error
    }
}

async function sendImage(data) {
    try{
        const headers = { 'Content-Type': 'multipart/form-data' }
        return await apiClient.post('/chats/image', data,{ ...headers})
    } catch (error){
        return error
    }
}

async function sendImages(data) {
    try{
        const headers = { 'Content-Type': 'multipart/form-data' }
        return await apiClient.post('/chats/images', data,{ ...headers})
    } catch (error){
        return error
    }
}

async function sendVideo(data) {
    try{
        const headers = { 'Content-Type': 'multipart/form-data' }
        return await apiClient.post('/chats/video', data,{ ...headers})
    } catch (error){
        return error
    }
}

async function sendVideos(data) {
    try{
        const headers = { 'Content-Type': 'multipart/form-data' }
        return await apiClient.post('/chats/videos', data,{ ...headers})
    } catch (error){
        return error
    }
}

async function sendDoc(data) {
    try{
        const headers = { 'Content-Type': 'multipart/form-data' }
        return await apiClient.post('/chats/doc', data,{ ...headers})
    } catch (error){
        return error
    }
}

async function sendDocs(data) {
    try{
        console.log(data)
        const headers = { 'Content-Type': 'multipart/form-data' }
        return await apiClient.post('/chats/documents', data,{ ...headers})
    } catch (error){
        return error
    }
}

async function validaChip(phones) {
    try{
        const data = {phones: phones}
        return await apiClient.post('/validates/chip', data)
    } catch (error){
        return error
    }
}


export {
    clientConnect,
    generateQrCode,
    sendMessages,
    sendImage,
    sendImages,
    sendVideo,
    sendVideos,
    sendDoc,
    sendDocs,
    contactInfo,
    clientStatus,
    clientDisconnect,
    validaChip
}
