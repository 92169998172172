import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Button, Card, Col, Form, Row, Container } from "react-bootstrap";
import bg1 from "../assets/img/bg1.jpg";
import axios from "../api/axios";
import { dashboardMenuSuperAdmin, dashboardMenuAdmin } from "../data/Menu";

const LOGIN_URL = '/auth/login';

export default function Login() {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef(null);
    const errRef = useRef(null);

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        userRef.current?.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user === '' || pwd === '') {
            setErrMsg('Preencha todos os campos');
            return;
        }
        
        setLoading(true);
        try {
            const response = await axios.post(LOGIN_URL, 
                JSON.stringify({ email: user, password: pwd }),
                { headers: { 'Content-Type': 'application/json' } }
            );
            
            const { accessToken, roles, name: username, email, apiToken } = response.data;
            
            setAuth({ user, username, email, pwd, accessToken, roles, apiToken });
            localStorage.setItem("persist", "true");
            localStorage.setItem("token", apiToken);
            
            const isSuperAdmin = Object.values(roles).includes(1000);
            localStorage.setItem('menuDashboard', JSON.stringify(isSuperAdmin ? dashboardMenuSuperAdmin : dashboardMenuAdmin));
            
            navigate(isSuperAdmin ? '/dashboard/super-admin' : '/dashboard', { replace: true });
        } catch (err) {
            setErrMsg(
                err.response?.status === 400 ? 'Servidor indisponível, tente novamente mais tarde' :
                err.response?.status === 401 ? 'Não autorizado' :
                'Falha no login, tente novamente mais tarde'
            );
            errRef.current?.focus();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Container fluid className="page-sign py-3 py-md-0">
            <Row className="g-0 h-100">
                <Col xs={12} md={7} lg={5} xl={4} className="d-flex align-items-center justify-content-center">
                    <Card className="card-sign w-100">
                        <Card.Header>
                            <Link to="/" className="header-logo mb-4">Acelera Apps</Link>
                            <Card.Title>Login</Card.Title>
                            {errMsg && (
                                <Card.Text>
                                    <span ref={errRef} className="text-danger" aria-live="assertive">{errMsg}</span>
                                </Card.Text>
                            )}
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Digite seu email"
                                        ref={userRef}
                                        value={user}
                                        onChange={(e) => setUser(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Senha</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Digite sua senha"
                                        value={pwd}
                                        onChange={(e) => setPwd(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button 
                                    type="submit" 
                                    className="btn-sign w-100" 
                                    disabled={loading}
                                >
                                    {loading ? 'Carregando...' : 'Login'}
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={5} lg={7} xl={8} className="d-none d-md-block">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <img src={bg1} className="auth-img img-fluid" alt="Background" style={{maxHeight: '100vh', objectFit: 'cover'}} />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}